import * as R from 'ramda';
import useSWR, {SWRConfiguration, SWRResponse} from 'swr';
import {fetcher, FetcherConfigEntityT} from './fetcher';
import {useAuth} from '../providers/auth/auth-provider';
import {NullableT} from '../definitions/common.definitions';

export function useGet<DataT, ParamsT = Object>(cfg: {
  key: NullableT<string>,
  config?: SWRConfiguration,
  params?: ParamsT,
  auth?: boolean,
  ver?: string,
}): SWRResponse<DataT> {
  const {
    token,
  } = useAuth();
  const {
    key,
    config,
    params,
    auth = true,
    ver,
  } = cfg;
  const $config = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    ...(config ?? {}),
  };
  // @ts-ignore
  let complexKey: NullableT<FetcherConfigEntityT> = R.isNil(key) ? null : {
    url: key,
    ver,
    params,
  }

  if (auth) {
    if (!Boolean(token) || R.isNil(complexKey)) {
      complexKey = null;
    } else {
      complexKey.token = token;
    }
  }

  // @ts-ignore
  return useSWR<DataT>(complexKey, fetcher, $config);
}
