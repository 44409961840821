import {createContext, FC, useContext} from "react";
import {useTransport} from "../../transport/transport-provider";
import {useGet} from "../../transport/useGet";
import {EndpointsEnum} from "../../constants/endpoints";
import {FileEntityT} from "../files/files-provider";
import {ArticleEntityT} from "../articles/articles-provider";
import {CommonEntityProvider, ICommonComponentProps} from "../../definitions/common.definitions";

export type PageEntityT = {
  id: number;
  parentId?: number;
  order: number;
  url: string;
  title: string;
  subTitle?: string;
  imageId?: number;
  imageUrl?: string;
  imageName?: string;
  image?: FileEntityT;
  isStatic?: boolean;
  icon?: string;
  hasDisclaimer: boolean;
  disclaimer?: string;
  disclaimerUrl?: string;
  disableCategory?: boolean;
  disableSubCategory?: boolean;
  hasDocuments: boolean;
  categoryId?: number;
  subCategoryId?: number;
  year?: number;
  hasArticle: boolean;
  articleId?: number;
  article?: ArticleEntityT;
  published: boolean;
}

export type PagesContextT = CommonEntityProvider<PageEntityT[]> & {
  open: (id: number) => Promise<PageEntityT>;
}

const context = createContext<PagesContextT>(null!);
const PagesContextProvider = context.Provider;
const usePages = () => useContext(context);

const PagesProvider: FC<ICommonComponentProps> = (props) => {
  const {
    children,
  } = props;

  const {
    send,
  } = useTransport();

  const {
    isLoading,
    data,
  } = useGet<PageEntityT[]>({
    key: EndpointsEnum.PAGES,
    auth: false,
  });

  const open = (id: number) => {
    return send<{ id: number }, PageEntityT>({
      method: 'GET',
      path: [EndpointsEnum.PAGES, id].join('/'),
      auth: false,
    });
  }

  const ctx: PagesContextT = {
    data,
    isLoading,
    open,
  }

  return (
    <PagesContextProvider
      value={ctx}
    >
      {children}
    </PagesContextProvider>
  )
}

export default PagesProvider;
export {
  PagesProvider,
  usePages,
}