import * as R from 'ramda';
import {createContext, FC, useContext, useEffect, useState} from 'react';
import {ICommonComponentProps} from '../../definitions/common.definitions';

export type AuthContextT = {
  token?: string;
  storeToken: (value: string) => void;
  clearToken: () => void;
  isAuthenticated: boolean;
}

const context = createContext<AuthContextT>(null!);
const AuthContextProvider = context.Provider;
const useAuth = () => useContext(context);

const AuthProvider: FC<ICommonComponentProps> = (props) => {
  const {
    children,
  } = props;
  const [token, setToken] = useState<string>();

  const getTokenFromStorage = () => {
    if (typeof window === 'undefined') {
      return;
    }
    const storedToken = localStorage.getItem('token');
    if (!R.isNil(storedToken)) {
      setToken(storedToken);
    }
    return storedToken;
  }

  useEffect(() => {
    getTokenFromStorage();
  }, []);

  const isAuthenticated = !R.isNil(token) || !R.isNil(getTokenFromStorage());

  const storeToken = (value: string) => {
    setToken(value);
    localStorage.setItem('token', value);
  }

  const clearToken = () => {
    setToken(undefined);
    localStorage.removeItem('token');
  }

  const ctx: AuthContextT = {
    token,
    storeToken,
    clearToken,
    isAuthenticated,
  }

  return (
    <AuthContextProvider
      value={ctx}
    >
      {children}
    </AuthContextProvider>
  );
}

export default AuthProvider;

export {
  AuthProvider,
  useAuth,
}
