import styles from './loading.page.module.css';
import { ProgressSpinner } from 'primereact/progressspinner';

const LoadingPage = () => {
  return (
    <div
      className={styles.wrapper}
    >
      <ProgressSpinner
        strokeWidth={'3px'}
      />
    </div>
  );
}

export default LoadingPage;