import {NavigationComponent} from '../../components/navigation/navigation.component';
import {Card} from 'primereact/card';

import styles from './error.module.css';
import {clsx} from 'clsx';
import {centerOfScreen} from '../../styles/prime-flex-classes.constants';
import {useNavigate, useRouteError} from 'react-router-dom';
import {Button} from 'primereact/button';

const ErrorPage = () => {
  const navigate = useNavigate();
  const error = useRouteError() as any;

  return (
    <>
      <div
        className={'min-h-screen'}
        style={{
          backgroundImage: 'url(images/carousel04_min.jpg)',
          backgroundSize: 'cover',
        }}
      >
        <NavigationComponent/>
        <div
          className={clsx('flex m-9', centerOfScreen)}
        >
          <Card
            title={'Что-то пошло не так 😓'}
            pt={{
              root: {
                className: styles['card_error__wrapper'],
              },
            }}
            footer={<Button icon={'pi pi-home'} label={'В начало'} onClick={() => navigate('/')}/>}
          >
            <p>Перезагрузите приложение.</p>
            {error?.statusText || error?.message ? (
              <p>{error.statusText || error.message}</p>
            ) : (
              <p>Неизвестная ошибка.</p>
            )}
          </Card>
        </div>
      </div>
    </>
  );
}

export default ErrorPage;