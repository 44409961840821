import {ICommonComponentProps} from "../../definitions/common.definitions";
import {PageEntityT} from "../../models/pages/pages-provider";
import {useGet} from "../../transport/useGet";
import {ArticleEntityT} from "../../models/articles/articles-provider";
import {EndpointsEnum} from "../../constants/endpoints";
import {sanitizeContent} from "../../utils/html";
// import {Image} from "primereact/image";
import {InfoPageLayout} from "../info-page-layout/info-page-layout.component";
import {replaceTags} from '../../utils/string';
import {useRecords} from '../../models/records/records-provider';
import DocumentManager from '../document-manager/document-manager';
import React, {useRef} from 'react';
import {useMountEffect} from "primereact/hooks";
import {Messages} from "primereact/messages";

export type UniversalPagePropsT = ICommonComponentProps & {
  entity: PageEntityT;
}

const UniversalPage = (props: UniversalPagePropsT) => {
  const {
    entity,
  } = props;

  const {
    title,
    hasArticle,
    hasDocuments,
    categoryId,
    subCategoryId,
    articleId,
    disableCategory,
    disableSubCategory,
    disclaimer,
  } = entity;

  const {
    mappedData,
  } = useRecords();

  const msgs = useRef<any>(null);

  useMountEffect(() => {
    if (msgs.current && disclaimer) {
      msgs.current.clear();
      msgs.current.show({
        id: '1',
        sticky: true,
        severity: 'info',
        summary: 'Уточнение',
        detail: disclaimer,
        closable: false,
      });
    }
  });

  const {
    data: article,
    isLoading: articleLoading,
  } = useGet<ArticleEntityT>({
    key: hasArticle ? [EndpointsEnum.ARTICLES, articleId].join('/') : null,
    auth: false,
  });

  return (
    <InfoPageLayout
      title={article?.title ?? title}
      progress={articleLoading}
    >
      {
        disclaimer && (
          <div className="flex my-3 min-w-full">
            <Messages ref={msgs}/>
          </div>
        )
      }
      {
        hasDocuments && (
          <DocumentManager
            initCategoryId={categoryId}
            initSubCategoryId={subCategoryId}
            disableCategory={disableCategory}
            disableSubCategory={disableSubCategory}
          />
        )
      }
      {
        hasArticle && article?.content && (
          <span
            dangerouslySetInnerHTML={sanitizeContent(replaceTags(article.content ?? '', mappedData))}
          />
        )
      }
      {/*<div*/}
      {/*  className={'flex flex-column align-items-center'}*/}
      {/*>*/}
      {/*  <Image*/}
      {/*    src={'/images/145035.jpg'}*/}
      {/*    width={'100%'}*/}
      {/*  />*/}
      {/*</div>*/}
    </InfoPageLayout>
  );
}

export default UniversalPage;