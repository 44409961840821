import * as R from 'ramda';
import {CarouselComponent} from '../../components/carousel/carousel.component';
import {useRecords} from '../../models/records/records-provider';

import styles from './home.module.css';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {useNavigate} from 'react-router-dom';
import {RoutesEnum} from '../../constants/routes';

export const HomePage = () => {
  const navigate = useNavigate();
  const {
    mappedData,
  } = useRecords();

  const items = [
    { id: 0, url: '', title: mappedData['title-01'], subTitle: mappedData['title-01'] }
  ];
  const cardProps = {
    pt: {
      root: {
        className: styles['home__card'],
      },
    }
  }

  const headerElement = (title: string, buttonConfig?: { cb: () => void, icon: string; label?: string }) => {
    const {
      cb,
      icon,
      label,
    } = buttonConfig ?? {};

    return (
      <div
        className={'flex justify-content-between'}
      >
        <div>{title}</div>
        <div>
          {
            !R.isNil(buttonConfig) && (
              <Button
                label={label}
                icon={icon}
                onClick={cb}
              />
            )
          }
        </div>
      </div>
    );
  }


  return (
    <div
      className={'min-h-screen'}
      style={{
        backgroundImage: 'url(images/carousel04_min.jpg)',
        backgroundSize: 'cover',
      }}
    >
      <div
        className={styles['wrapper']}
      >
        <div style={{ height: 100 }} />
        <CarouselComponent
          items={items}
        />
        <div
          className={styles['container']}
        >
          <div className={styles['col']}>
            <Card
              title={
                headerElement(
                  'Отключения',
                  { icon: 'pi pi-bolt', cb: () => navigate([RoutesEnum.CONSUMERS, RoutesEnum.SHUTDOWNS].join('/')) }
                )
              }
              {...cardProps}
            >
              <p className="m-0">
                Плановые и внеплановые отключения. Дополнительная информация по телефону горячей линии 8 (800) 700-46-71.
              </p>
            </Card>
          </div>
          <div className={styles['col']}>
            <Card
              title={
                headerElement(
                  'Личный кабинет',
                  { icon: 'pi pi-user', cb: () => window.open('https://lk.sibseti.su/', '_blank') }
                )
              }
              {...cardProps}
            >
              <p className="m-0">
                Личный кабинет потребителя/заявителя. Подать заявку.
              </p>
            </Card>
          </div>
          <div className={styles['col']}>
            <Card
              title={
                headerElement(
                  'Отчетность',
                  { icon: 'pi pi-file-check', cb: () => navigate([RoutesEnum.DOCUMENTS, RoutesEnum.ALL].join('/')) }
                )
              }
              {...cardProps}
            >
              <p className="m-0">
                Для контролирующих органов
              </p>
            </Card>
          </div>
          <div className={styles['col']}>
            <Card
              title={
                headerElement(
                  'Горячая линия',
                  { icon: 'pi pi-phone', cb: () => window.open('tel:88007004671') }
                )
              }
              subTitle={'8(800)700-46-71'}
              {...cardProps}
            >
              <p className="m-0">
                Центральная диспетчерская служба городского хозяйства тел.: 005
              </p>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
