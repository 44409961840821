import {createContext, FC, useContext, useState} from 'react';
import {useTransport} from '../../transport/transport-provider';
import {useAuth} from '../../providers/auth/auth-provider';
import {EndpointsEnum} from '../../constants/endpoints';
import {useNavigate} from 'react-router-dom';
import {CommonEntityProvider, ICommonComponentProps} from '../../definitions/common.definitions';
import {RoutesEnum} from '../../constants/routes';

export type UserContextT = CommonEntityProvider & {
  login: (data: LoginApiRequestT) => Promise<void>;
  logout: () => void;
  signup: any;
  getById: (data: any) => void;
}

export type LoginApiRequestT = {
  login: string;
  password: string;
}

export type LoginApiResponseT = {
  access_token: string;
}

const context = createContext<UserContextT>(null!);
const UserContextProvider = context.Provider;
const useUser = () => useContext(context);

const UserProvider: FC<ICommonComponentProps> = (props) => {
  const [progress, setProgress] = useState(false);

  const {
    children,
  } = props;
  const {
    send,
  } = useTransport();
  const {
    storeToken,
    clearToken,
  } = useAuth();
  const navigate = useNavigate();

  const login = async (data: LoginApiRequestT) => {
    setProgress(true);
    try {
      const result = await send<LoginApiRequestT, LoginApiResponseT>({
        path: EndpointsEnum.LOGIN,
        data,
        auth: false,
      });
      storeToken(result.access_token);
      setProgress(false);
      navigate(RoutesEnum.HOME);
    } catch (e: any) {
      console.log('login error');
      setProgress(false);
    }
  }

  const getById = async (id: number) => {
    try {
      const result = await send({
        path: '/users',
        method: 'GET',
        data: {
          id,
        }
      });
      console.log('result', result);
    } catch (e) {
      console.log('error', e);
    }
  }

  const logout = () => {
    clearToken();
    navigate(RoutesEnum.HOME);
  }

  const signup = (data: any) => {
    send({ path: EndpointsEnum.USERS, data });
  }

  const ctx: UserContextT = {
    login,
    logout,
    signup,
    getById,
    isLoading: progress,
  };

  return (
    <UserContextProvider value={ctx}>
      {children}
    </UserContextProvider>
  );
}

export default UserProvider;
export {
  UserProvider,
  useUser,
}
