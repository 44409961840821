export enum RoutesEnum {
  ABOUT = 'about',
  ALL = 'all',
  CALC = 'calc',
  COMPANY = 'company',
  CONSUMERS = 'consumers',
  DOCUMENTS = 'documents',
  HOME ='/',
  MANAGMENT = 'management',
  SHUTDOWNS = 'shutdowns',
}

export enum PagesEnum {
  ABOUT = 5,
  ALL = 7,
  CALC = 3,
  COMPANY = 4,
  CONSUMERS = 1,
  DOCUMENTS = 2,
  HOME =0,
  MANAGMENT = 6,
  SHUTDOWNS = 91,
}