import React from 'react';
import {IconsEnum} from '../constants/icons';

export interface ICommonComponentProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export type UndefT<TValue> = TValue | null;
export type NullableT<Value> = UndefT<Value> | undefined;
export type EntityIdT = number | string;

export type FileDocumentEntity = {
  id?: EntityIdT;
  date: string;
  name: string;
  url: string;
  type: FileDocumentTypeEnum;
  category: FileDocumentCategoryEnum;
  subCategory: FileDocumentSubCategoryEnum;
}

export type FileCategoryEntity = {
  id: EntityIdT;
  name: string;
  parentId?: number;
}

export type ApiResponseEntityT<EntityT> = {
  data?: EntityT;
  progress?: boolean;
}

export enum FileDocumentTypeEnum {
  PDF = 'pdf',
  XLS = 'xls',
  DOC = 'doc',
  XML = 'xml',
  IMG = 'img',
}

export enum FileDocumentCategoryEnum {
  DISCLOSURE,
  PROCUREMENT,
  REPORTS,
}

export type CommonEntityProvider<DataT = any> = {
  data?: DataT;
  isLoading: boolean;
}

export enum FileDocumentSubCategoryEnum {
  SEPARATE_ACCOUNTING,
}

export type ApiErrorEntity = {
  subCode: number;
  message: string;
}

export enum FileExtensionEnum {
  XLS = 'xls',
  XLSX = 'xlsx',
  DOC = 'doc',
  DOCX = 'docx',
  TXT = 'txt',
  PDF = 'pdf',
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
}

export const ICONS_MAP = Object.freeze({
  [FileExtensionEnum.DOC]: IconsEnum.WORD,
  [FileExtensionEnum.PNG]: IconsEnum.IMAGE,
  [FileExtensionEnum.JPG]: IconsEnum.IMAGE,
  [FileExtensionEnum.PDF]: IconsEnum.PDF,
  [FileExtensionEnum.TXT]: IconsEnum.WORD,
  [FileExtensionEnum.XLS]: IconsEnum.EXCEL,
  [FileExtensionEnum.XLSX]: IconsEnum.EXCEL,
  [FileExtensionEnum.JPEG]: IconsEnum.IMAGE,
  [FileExtensionEnum.DOCX]: IconsEnum.WORD,
});