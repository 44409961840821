import {ICommonComponentProps} from '../../definitions/common.definitions';
import {FC} from 'react';
import styles from './info-page-layout.module.css';
import {ProgressBar} from "primereact/progressbar";

export interface IInfoPageLayoutComponentProps
  extends ICommonComponentProps {
  titleImageUrl?: string;
  title: string;
  progress?: boolean;
}

export const InfoPageLayout: FC<IInfoPageLayoutComponentProps> = (props) => {
  const {
    children,
    title,
    titleImageUrl,
    progress,
  } = props;

  return (
    <div
      className={styles['layout-wrapper']}
    >
      {
        titleImageUrl && (
          <div
            className={styles['title-image']}
            style={{
              backgroundImage: `url("${titleImageUrl}")`,
            }}
          >
          </div>
        )
      }
      <div
        className={styles['content-wrapper']}
      >
        {
          progress && (
            <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
          )
        }
        <div
          className={styles['title']}
        >
          {title}
        </div>
        <div
          className={styles['content']}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
