export const replaceTags = (inputString: string, tagObject: {[key: string]: string}): string => {
  const tagRegex = /{{(.*?)}}/g;
  const replacedString = inputString.replace(tagRegex, function(match, tag) {
    tag = tag.trim();
    if (tagObject.hasOwnProperty(tag)) {
      return tagObject[tag];
    } else {
      return match;
    }
  });

  return replacedString;
}