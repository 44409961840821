import {createContext, FC, useContext} from "react";

import {ArticleTypeEntityT} from "../article-types/article-types-provider";
import {useGet} from "../../transport/useGet";
import {EndpointsEnum} from "../../constants/endpoints";
import {useTransport} from "../../transport/transport-provider";
import {CommonEntityProvider, ICommonComponentProps} from "../../definitions/common.definitions";

export type ArticleEntityT = {
  id: number;
  title: string;
  content: string;
  published: boolean;
  type: ArticleTypeEntityT;
  typeId: number;
}

export type ArticlesContextT = CommonEntityProvider<ArticleEntityT[]> & {
  open: (id: number) => Promise<ArticleEntityT>;
  mappedData: Record<number, ArticleEntityT>
}

const context = createContext<ArticlesContextT>(null!);
const ArticlesContextProvider = context.Provider;
const useArticle = () => useContext(context);

const ArticlesProvider: FC<ICommonComponentProps> = (props) => {
  const {
    children,
  } = props;

  const {
    send,
  } = useTransport();

  const {
    isLoading,
    data,
  } = useGet<ArticleEntityT[]>({
    key: EndpointsEnum.ARTICLES,
    auth: false,
  });

  const open = (id: number) => {
    return send<{ id: number }, ArticleEntityT>({
      method: 'GET',
      path: [EndpointsEnum.ARTICLES, id].join('/'),
      auth: false,
    });
  }

  const ctx: ArticlesContextT = {
    open,
    data,
    isLoading,
    mappedData: (data ?? []).reduce((acc, curr) => ({
      ...acc,
      [curr.id] : curr,
    }), {}),
  }

  return (
    <ArticlesContextProvider
      value={ctx}
    >
      {children}
    </ArticlesContextProvider>
  )
}

export default ArticlesProvider;
export {
  ArticlesProvider,
  useArticle,
}