export enum EndpointsEnum {
  ARTICLES = 'articles',
  ARTICLE_TYPES = 'article-types',
  DISTRICTS = 'districts',
  DOCUMENTS = 'documents',
  DOCUMENT_TYPE = 'document-type',
  FACILITIES = 'facilities',
  FILE = 'file',
  LOGIN = 'auth/login',
  PAGES = 'pages',
  RECORDS = 'records',
  SHUTDOWNS = 'shutdowns',
  UPLOAD = 'upload',
  USERS = 'users',
}