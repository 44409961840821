import * as R from 'ramda';
import {AxiosError, AxiosResponse} from 'axios';
import React, {createContext, FC, useContext} from 'react';

import axios from './axios';
import {useMessage} from '../providers/message/message-provider';
import {useAuth} from '../providers/auth/auth-provider';
import {ApiErrorEntity, ICommonComponentProps} from '../definitions/common.definitions';

export type SendRequestT<TProps> = {
  path: string;
  data?: TProps;
  auth?: boolean;
  method?: 'GET' | 'POST' | 'DELETE' | 'PATCH' | 'UPLOAD';
  headers?: Record<string, string>;
}

export type TransportContextT = {
  send: <TProps = any, TResponse = any>(props: SendRequestT<TProps>) => Promise<TResponse>;
}

const context = createContext<TransportContextT>(null!);
const TransportContextProvider = context.Provider;
const useTransport = () => useContext(context);

const TransportProvider: FC<ICommonComponentProps> = (props) => {
  const {
    children,
  } = props;
  const {
    showError,
  } = useMessage();
  const {
    token,
  } = useAuth();

  const send = async <TProps = any, TResponse = any>(cfg: SendRequestT<TProps>): Promise<TResponse> => {
    const {
      auth = true,
      method = 'POST',
      data,
      path,
      headers = {},
    } = cfg;
    let result: AxiosResponse<TResponse>;
    try {
      switch (method) {
        case 'UPLOAD':
          result = await axios.post(
            path,
            data,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                ...(auth ? { 'Authorization': `Bearer ${token}` } : {}),
                ...headers,
              },
            });
          break;
        case 'POST':
        case 'PATCH':
          result = await axios.post(
            path,
            JSON.stringify(data),
            {
              headers: {
                'Content-Type': 'application/json',
                ...(auth ? { 'Authorization': `Bearer ${token}` } : {}),
                ...headers,
              },
            });
          break;
        case 'DELETE':
          result = await axios.delete(
            `${path}/${data}`,
            {
              headers: {
                ...(auth ? { 'Authorization': `Bearer ${token}` } : {}),
                ...headers,
              },
            }
          );
          break;
        case 'GET':
        default:
          result = await axios.get(
            path,
            {
              headers: {
                ...(auth ? { 'Authorization': `Bearer ${token}` } : {}),
                ...headers,
              },
              params: data,
            }
          );

          break;
      }

    } catch (error: any) {
      if (error instanceof AxiosError) {
        const data = error?.response?.data as ApiErrorEntity;
        if (R.isNil(data)) {
          showError({ message: error.message });
          throw error;
        }

        showError({ message: data.message });
        throw data;
      }
      showError({ message: error.message });
      throw error;
    }
    return result.data;
  }

  const ctx = {
    send,
  }
  return (
    <TransportContextProvider
      value={ctx}
    >
      {children}
    </TransportContextProvider>
  );
}

export default TransportProvider;

export {
  TransportProvider,
  useTransport,
}
