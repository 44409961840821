import * as R from 'ramda';
import {createContext, FC, useContext} from 'react';
import {EndpointsEnum} from '../../constants/endpoints';
import {useTransport} from '../../transport/transport-provider';
import {useGet} from '../../transport/useGet';
import {CommonEntityProvider, ICommonComponentProps} from '../../definitions/common.definitions';

export type DocumentTypeEntityT = {
  id: number;
  name: string;
  description?: string;
  parentId?: number;
}

export type CreateDocumentTypeApiRequestT = Omit<DocumentTypeEntityT, 'id'>;

export type UpdateDocumentTypeApiRequestT = Partial<DocumentTypeEntityT>;

export type DocumentTypeContextT = CommonEntityProvider<DocumentTypeEntityT[]> & {
  create: (entity: CreateDocumentTypeApiRequestT) => any;
  categories: DocumentTypeEntityT[];
  subCategories: (id?: number) => DocumentTypeEntityT[];
}

const context = createContext<DocumentTypeContextT>(null!);
const DocumentTypeContextProvider = context.Provider;
const useDocumentType = () => useContext(context);

const DocumentTypeProvider: FC<ICommonComponentProps> = (props) => {
  const {
    children,
  } = props;
  const {
    data,
    isLoading,
  } = useGet<DocumentTypeEntityT[]>({
    key: EndpointsEnum.DOCUMENT_TYPE,
    auth: false,
  });

  const {
    send,
  } = useTransport();

  const create = (entity: CreateDocumentTypeApiRequestT) => {
    send<CreateDocumentTypeApiRequestT>({
      path: EndpointsEnum.DOCUMENT_TYPE,
      data: entity,
    });
  }

  const categories = (data ?? []).filter((itm) => R.isNil(itm.parentId));
  const subCategories = (id?: number) => {
    if (R.isNil(id)) {
      return [];
    }

    return (data ?? []).filter((itm) => itm.parentId === id);
  }

  const ctx: DocumentTypeContextT = {
    create,
    data,
    isLoading,
    categories,
    subCategories,
  }

  return (
    <DocumentTypeContextProvider
      value={ctx}
    >
      {children}
    </DocumentTypeContextProvider>
  );
}

export default DocumentTypeProvider;

export {
  DocumentTypeProvider,
  useDocumentType,
}
