import * as R from 'ramda';
import React from 'react';
import {createBrowserRouter, Outlet, RouteObject} from 'react-router-dom';

import {HomePage} from '../pages/home/home.page';
import UniversalPage from "../components/universal-page/univarsal-page";
import {NavigationComponent} from '../components/navigation/navigation.component';

import UserProvider from '../models/user/user-provider';
import DocumentTypeProvider from '../models/document-type/document-type-provider';
import ArticlesProvider from "../models/articles/articles-provider";
import {PageEntityT} from "../models/pages/pages-provider";
import ErrorPage from '../pages/error/error.page';
import {RoutesEnum} from '../constants/routes';
import ShutdownsPage from '../pages/shutdowns/shutdowns.page';
import ShutdownsProvider from '../models/shutdowns/shutdowns-provider';

const ProvidersLayout = () => (
  <UserProvider>
    <DocumentTypeProvider>
      <ArticlesProvider>
        <NavigationComponent/>
        <Outlet/>
      </ArticlesProvider>
    </DocumentTypeProvider>
  </UserProvider>
);

const getPageElement = (page: PageEntityT): React.ReactNode => {
  if (page.url === RoutesEnum.SHUTDOWNS) {
    return <ShutdownsProvider><ShutdownsPage/></ShutdownsProvider>;
  }
  return (page.hasArticle || page.hasDocuments) ? <UniversalPage entity={page}/> : null;
}

const mapPageToRoute = (page: PageEntityT, list: PageEntityT[]): RouteObject => ({
  path: page.url,
  element: getPageElement(page),
  children: list
    .filter((itm) => itm.parentId === page.id)
    .map((itm) => mapPageToRoute(itm, list)),
});

const router = (pages: PageEntityT[]) => {
  const tree = (pages ?? [])
    .filter((page) => R.isNil(page.parentId))
    .map((page) => mapPageToRoute(page, pages));

  return createBrowserRouter([
    {
      element: <ProvidersLayout/>,
      children: [
        {
          path: '/',
          element: <HomePage/>,
          errorElement: <ErrorPage/>,
        },
        ...tree,
      ],
      errorElement: <ErrorPage/>,
    },
  ]);
};

export default router;
