import {Carousel} from 'primereact/carousel';
import {FC} from 'react';
import {ICarouselComponentProps, ICarouselScreenEntity} from './carousel.interface';
import styles from './carousel.module.css';

import {Card} from 'primereact/card';

export const CarouselComponent: FC<ICarouselComponentProps> = ({ items }) => {
  const productTemplate = (product: ICarouselScreenEntity) => (
    <div
      className={'flex'}
    >
      <div></div>
      <div
        className={styles['z-carousel__card_text']}
      >
        {product?.subTitle}
      </div>
    </div>
  );

  return (
    <Card
      style={{ margin: 16 }}
      pt={{
        root: {
          className: styles['z-carousel__card_root'],
        },
      }}
    >
      <Carousel
        value={items}
        numVisible={1}
        numScroll={1}
        itemTemplate={productTemplate}
        pt={{
          indicatorButton: {
            style: { backgroundColor: 'white' },
          },
        }}
      />
    </Card>
  );
}
