import {InfoPageLayout} from '../../components/info-page-layout/info-page-layout.component';
import SGrid from '../../components/grid/grid.component';
import {ShutdownEntityT, useShutdowns} from '../../models/shutdowns/shutdowns-provider';
import {ColumnProps} from 'primereact/column';
import moment from 'moment';
import React from 'react';

const ShutdownsPage = () => {
  const {
    isLoading,
    data,
  } = useShutdowns();

  const columns: ColumnProps[] = [
    {
      header: 'Район',
      body: (item: ShutdownEntityT) => item.district.name,
    },
    {
      header: 'Адрес',
      field: 'address',
    },
    {
      header: 'Подробности',
      field: 'description',
    },
    {
      header: 'Отключение до, дата/время',
      body: (item: ShutdownEntityT) =>  (<span>{moment.utc(item.toDate).local().format('DD MMMM YYYY hh:MM')}</span>)
    }
  ];

  return (
    <InfoPageLayout
      title={'Информация об отключениях'}
    >
      <SGrid
        dataKey={'id'}
        loading={isLoading}
        value={data}
        columns={columns}
        emptyMessage={'В настоящий момент отключений не зафиксировано'}
      />
    </InfoPageLayout>
  );
}

export default ShutdownsPage;