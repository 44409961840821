import React from 'react';
import {FC} from 'react';
import styles from './navigation.module.css';
import {MegaMenu} from 'primereact/megamenu';
import {ICommonComponentProps} from '../../definitions/common.definitions';
import {Link, useNavigate} from 'react-router-dom';
import {NAVIGATION_ITEMS} from '../../constants/navigation';
import {usePages} from "../../models/pages/pages-provider";

export interface INavigationComponentProps
  extends ICommonComponentProps {
}

export const NavigationComponent: FC<INavigationComponentProps> = (props) => {
  const navigate = useNavigate();
  const {
    data = [],
  } = usePages();

  const logoElement = (
    <div
      className={'flex mr-3'}
    >
      <Link
        to={'/'}
      >
        <img
          src={'/images/logo80.png'}
          width={54}
          height={54}
          alt={'Электрические сети Сибири'}
          style={{ marginRight: 16 }}
        />
      </Link>
      <span
        className={styles['logo-text']}
      >
        Электрические<br/>
        Сети Сибири
      </span>
    </div>
  );

  const navigationLogoElement = (
    <div
      className={styles['navigation-logo']}
    >
      {logoElement}
    </div>
  );

  const endElement = (
      <div
        className={styles['navigation-end-logo']}
      >
        {logoElement}
      </div>
    );

  const getMenuItemStyle = (context: any) => {
    switch (true) {
      case context.focused:
        return styles['menu-action-focused'];
      case context.active:
        return styles['menu-action-active'];
      default:
        return styles['menu-action'];
    }
  }

  return (
    <div
      className={styles['navigation-wrapper']}
    >
      <MegaMenu
        model={NAVIGATION_ITEMS({ navigate, pages: data })}
        start={navigationLogoElement}
        end={endElement}
        breakpoint={'780px'}
        orientation={'horizontal'}
        pt={{
          root: {
            className: styles['menu-wrapper'],
          },
          menu: {
            className: styles['menu'],
          },
          // @ts-ignore
          action: ({ context }) => ({
            className: getMenuItemStyle(context),
          }),
          // @ts-ignore
          headerAction: ({ context }) => ({
            className: getMenuItemStyle(context),
          }),
          label: {
            className: styles['menu-label'],
          },
          panel: {
            className: styles['menu-panel'],
          },
          submenuHeader: {
            className: styles['menu-submenu-header'],
          },
          submenuIcon: {
            className: styles['menu-action-icon'],
          },
          // @ts-ignore
          menubuttonicon: {
            className: styles['menu-action-popup-icon'],
          }
        }}
      />
    </div>
  );
}
