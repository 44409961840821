// @ts-ignore
import sanitizeHtml from 'sanitize-html';

const sanitizeOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'span'],
  allowedAttributes: {
    'a': ['href'],
    'span': ['style'],
    'p': ['style'],
  },
};

export const sanitizeContent = (content: string) => {
  return { __html: sanitizeHtml(content, sanitizeOptions) as TrustedHTML };
}