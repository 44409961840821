import React, {createContext, FC, useContext, useRef} from 'react';
import {Toast} from 'primereact/toast';
import {ICommonComponentProps} from '../../definitions/common.definitions';

export type MessageContextT = {
  showMessage: (cfg: { message: string }) => void,
  showError: (cfg: { message: string }) => void,
}

const context = createContext<MessageContextT>(null!);
const MessageContextProvider = context.Provider;
const useMessage = () => useContext(context);

const MessageProvider: FC<ICommonComponentProps> = (props) => {
  const {
    children,
  } = props;
  const toast = useRef<Toast>(null);

  const showMessage = (cfg: { message: string }) => {
    toast.current?.show({ severity: 'info', summary: 'Инфо', detail: cfg.message });
  }

  const showError = (cfg: { message: string }) => {
    toast.current?.show({ severity: 'error', summary: 'Ошибка', detail: cfg.message });
  }

  const ctx = {
    showMessage,
    showError,
  }

  return (
    <MessageContextProvider
      value={ctx}
    >
      {children}
      <Toast ref={toast} />
    </MessageContextProvider>
  );
}

export default MessageProvider;

export {
  useMessage,
  MessageProvider
}
