import {createContext, FC, useContext} from "react";
import {useTransport} from "../../transport/transport-provider";
import {useGet} from "../../transport/useGet";
import {EndpointsEnum} from "../../constants/endpoints";
import {CommonEntityProvider, ICommonComponentProps} from "../../definitions/common.definitions";

export type RecordEntityT = {
  id: number;
  name: string;
  value: string;
}

export type RecordsContextT = CommonEntityProvider<RecordEntityT[]> & {
  open: (id: number) => Promise<RecordEntityT>;
  mappedData: Record<string, string>;
}

const context = createContext<RecordsContextT>(null!);
const RecordsContextProvider = context.Provider;
const useRecords = () => useContext(context);

const RecordsProvider: FC<ICommonComponentProps> = (props) => {
  const {
    children,
  } = props;

  const {
    send,
  } = useTransport();

  const {
    isLoading,
    data,
  } = useGet<RecordEntityT[]>({
    key: EndpointsEnum.RECORDS,
    auth: false,
  });

  const open = (id: number) => {
    return send<{ id: number }, RecordEntityT>({
      method: 'GET',
      path: [EndpointsEnum.RECORDS, id].join('/'),
      auth: false,
    });
  }

  const mappedData = (data ?? []).reduce((acc, curr) => ({
    ...acc,
    [curr.name]: curr.value,
  }), {});

  const ctx: RecordsContextT = {
    open,
    data,
    mappedData,
    isLoading,
  }

  return (
    <RecordsContextProvider
      value={ctx}
    >
      {children}
    </RecordsContextProvider>
  )
}

export default RecordsProvider;
export {
  RecordsProvider,
  useRecords,
}