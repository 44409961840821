import * as R from "ramda";
import React, {FC, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import moment from "moment/moment";

import {Dropdown} from "primereact/dropdown";

import SGrid, {SGridProps} from '../grid/grid.component';
import {DocumentEntityT} from "../../models/documents/documents-provider";
import {getUrlExtension} from "../../utils/file";
import {IconsEnum} from "../../constants/icons";
import {FileExtensionEnum, ICONS_MAP} from "../../definitions/common.definitions";
import {useDocumentType} from "../../models/document-type/document-type-provider";
import {useGet} from "../../transport/useGet";
import {EndpointsEnum} from "../../constants/endpoints";

export type DocumentManagerPropsT = {
  gridConfig?: SGridProps;
  initCategoryId?: number;
  initSubCategoryId?: number;
  disableCategory?: boolean;
  disableSubCategory?: boolean;
  year?: number;
}

const years = Array.from({ length: 13 }, (v, i) => ({
  label: `20${i + 13}`,
  value: 2000 + i + 13,
}));

const DocumentManager:FC<DocumentManagerPropsT> = (props) => {
  const {
    gridConfig,
    initCategoryId,
    initSubCategoryId,
    disableCategory,
    disableSubCategory,
    year,
  } = props;

  const [ category, setCategory ] = useState<number>();
  const [ subCategory, setSubCategory ] = useState<number>();
  const [ $year, set$Year ] = useState({ value: 2024 });

  useEffect(() => {
    setCategory((initCategoryId));
  }, [initCategoryId]);

  useEffect(() => {
    setSubCategory((initSubCategoryId));
  }, [initSubCategoryId]);

  useEffect(() => {
    if (year) {
      set$Year({ value: year });
    }
  }, [year]);

  const {
    categories,
    subCategories,
    isLoading,
  } = useDocumentType();
  const documentTypeId = subCategory || category;
  const {
    data: documentsData = [],
    isLoading: documentsIsLoading,
  } = useGet<DocumentEntityT[]>({
    key: R.isNil(documentTypeId) ? null : EndpointsEnum.DOCUMENTS,
    auth: false,
    params: {
      documentTypeId,
      year: $year?.value,
    }
  });

  const asIconElement = (doc: DocumentEntityT) => {
    const ext = getUrlExtension(doc.fileUrl);
    let iconName = IconsEnum.FILE;
    if (!R.isNil(ext)) {
      iconName = ICONS_MAP[ext as FileExtensionEnum] ?? IconsEnum.FILE;
    }

    return <i className={`pi ${iconName}`}></i>;
  }

  const columns = [
    {
      field: 'name',
      body: (itm: DocumentEntityT) => <Link target={'_blank'} download={true} to={itm.fileUrl}>{itm.name || itm.fileName}</Link>
    },
    {
      style: { width: '200px' },
      body: (itm: DocumentEntityT) => (<span>{moment.utc(itm.publishedDate).local().format('DD MMMM YYYY hh:MM')}</span>),
      header: 'Дата публикации',
    },
    {
      field: 'icon',
      header: 'Тип документа',
      body: asIconElement,
      style: { width: '50px' },
    }
  ];

  return (
    <div>
      <div
        className={'my-2'}
      >
        <Dropdown
          value={category}
          onChange={(e) => setCategory(e.value)}
          optionLabel={'name'}
          optionValue={'id'}
          options={categories}
          showClear={true}
          placeholder={'Категория'}
          className={'w-full md:w-14rem ml-2 mt-2'}
          emptyMessage={'Нет данных'}
          loading={isLoading}
          disabled={disableCategory}
        />
        {
          !R.isEmpty(subCategories) && (
            <Dropdown
              value={subCategory}
              options={subCategories(category)}
              optionLabel={'name'}
              optionValue={'id'}
              showClear={true}
              onChange={(e) => setSubCategory(e.value)}
              placeholder={'Подкатегория'}
              className={'w-full md:w-14rem ml-2 mt-2'}
              emptyMessage={'Нет данных'}
              loading={isLoading}
              disabled={disableSubCategory}
            />
          )
        }
        <Dropdown
          value={$year}
          onChange={(e) => set$Year(e.value)}
          options={years}
          multiple={false}
          optionValue={'id'}
          showClear={true}
          placeholder={'Год'}
          className={'w-full md:w-8rem ml-2 mt-2'}
          emptyMessage={'Нет данных'}
          loading={isLoading}
        />
      </div>
      <SGrid
        dataKey={'id'}
        loading={documentsIsLoading || isLoading}
        showHeaders={false}
        value={documentsData}
        columns={columns}
        {...gridConfig}
      />
    </div>
  );
}

export default DocumentManager;
