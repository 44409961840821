import * as R from 'ramda';

import {NavigateFunction} from 'react-router-dom';
import {MenuItem} from "primereact/menuitem";
import {PageEntityT} from "../models/pages/pages-provider";

export type NavigatorMenuItemsContext = {
  navigate: NavigateFunction;
  pages: PageEntityT[];
}

const mapPageToMenu = (cfg: {
  page: PageEntityT;
  parentUrl?: string;
  list: PageEntityT[];
  navigate: NavigateFunction;
}): MenuItem => {
  const {
    navigate,
    page,
    parentUrl,
    list,
  } = cfg;
  const currentUrl = parentUrl ? [parentUrl, page.url].join('/') : page.url;
  const items = list
    .filter((itm) => itm.parentId === page.id)
    .sort(orderSorter)
    .map((itm) => mapPageToMenu({
    page: itm,
    list,
    navigate,
    parentUrl: currentUrl,
  }));

  return {
    id: page.url,
    label: page.title,
    ...((items.length === 0 && ((page.hasDocuments || page.hasArticle) || page.isStatic)) ? { command: () => navigate(currentUrl) } : {}),
    ...(items.length > 0 ? { items: [[{ items }]] } : {}),
  }
}

const generatePages = ({navigate, pages}: NavigatorMenuItemsContext): MenuItem[] => {
  return (pages ?? [])
    .filter((page) => R.isNil(page.parentId))
    .sort(orderSorter)
    .map((page) => mapPageToMenu({
        page,
        list: pages,
        navigate,
      }
    ));
}

export const NAVIGATION_ITEMS = ({navigate, pages}: NavigatorMenuItemsContext): MenuItem[] => {
  return generatePages({navigate, pages});
}

const orderSorter = (item1: PageEntityT, item2: PageEntityT) => {
  if (item1.order < item2.order) {
    return -1;
  } else if (item1.order > item2.order) {
    return 1;
  }
  return 0;
}
// ([
//   {
//     label: 'Потребителям',
//     items: [
//       [
//         {
//           items: [
//             { label: 'Плановые отключения' },
//             // { label: 'Технологическое присоединение' },
//             // { label: 'Передача электроэнергии' },
//             { label: 'Карта присутствия' },
//             { label: 'Личный кабинет' },
//           ]
//         },
//       ],
//     ],
//   },
//   {
//     label: 'Документы',
//     items: [
//       [
//         {
//           items: [
//             { label: 'Раскрытие информации', command: () => navigate('/documents/disclosure') },
//           ]
//         }
//       ]
//     ]
//   },
//   {
//     label: 'Калькулятор',
//   },
//   {
//     label: 'Компания',
//     items: [
//       [
//         {
//           items: [
//             { label: 'О комании', url: '/company/about' },
//             { label: 'Контакты', url: '/company/contacts' },
//           ],
//         },
//       ],
//       // [
//       //   {
//       //     label: 'ЭСС',
//       //     items: [
//       //       { label: 'О нас', url: '/company/about' },
//       //       { label: 'Контакты', url: '/company/contacts' },
//       //       // { label: 'Документы' },
//       //       // { label: 'Реквизиты' },
//       //       // { label: 'Руководство' },
//       //       // { label: 'Миссия и ценности' },
//       //       // { label: 'Информационная детяельность' },
//       //       // { label: 'Электролаборатория' },
//       //     ],
//       //   },
//       // ],
//       // [
//       //   {
//       //     label: 'Документы',
//       //     items: [
//       //       { label: 'Раскрытие информации' },
//       //       // { label: 'Оценка условий труда' },
//       //       // { label: 'Долгосрочные параметры регулирования' },
//       //       // { label: 'Поданные заявки на техническое присоединение' },
//       //       // { label: 'Заключённые договора на техническое присоединение' },
//       //       // { label: 'Закупочная деятельность' },
//       //     ],
//       //   }
//       // ],
//       // [
//       //   {
//       //     label: 'Финансовые показатели',
//       //     items: [
//       //       { label: 'Раздельный учет' },
//       //       { label: 'Финансовая информация' },
//       //       { label: 'Учётная политика' },
//       //     ]
//       //   }
//       // ]
//     ],
//   },
// ]);
