import {createContext, FC, useContext} from 'react';
import {useTransport} from '../../transport/transport-provider';
import {useGet} from '../../transport/useGet';
import {EndpointsEnum} from '../../constants/endpoints';
import {CommonEntityProvider, ICommonComponentProps} from '../../definitions/common.definitions';
import {FacilityEntityT} from '../facilities/facilities-provider';
import {DistrictEntityT} from '../districts/districts-provider';

export type ShutdownEntityT = {
  id: number;
  name: string;
  facilityId?: number;
  facility: FacilityEntityT;
  district: DistrictEntityT;
  districtId?: number;
  address: string;
  description?: string;
  published: boolean;
  fromDate: string;
  $$fromHr?: number;
  $$fromMin?: number;
  toDate: string;
  $$toHr?: number;
  $$toMin?: number;
}

export type ShutdownsContextT = CommonEntityProvider<ShutdownEntityT[]> & {
  open: (id: number) => Promise<ShutdownEntityT>;
}

const context = createContext<ShutdownsContextT>(null!);
const ShutdownsContextProvider = context.Provider;
const useShutdowns = () => useContext(context);

const ShutdownsProvider: FC<ICommonComponentProps> = (props) => {
  const {
    children,
  } = props;

  const {
    send,
  } = useTransport();

  const {
    isLoading,
    data,
  } = useGet<ShutdownEntityT[]>({
    key: EndpointsEnum.SHUTDOWNS,
    auth: false,
  });

  const open = (id: number) => {
    return send<{ id: number }, ShutdownEntityT>({
      method: 'GET',
      path: [EndpointsEnum.SHUTDOWNS, id].join('/'),
      auth: false,
    });
  }

  const ctx: ShutdownsContextT = {
    open,
    data,
    isLoading,
  }

  return (
    <ShutdownsContextProvider
      value={ctx}
    >
      {children}
    </ShutdownsContextProvider>
  )
}

export default ShutdownsProvider;
export {
  ShutdownsProvider,
  useShutdowns,
}